import React from "react";

const Button = (props) => {
  return (
    <button onClick={props.onClick()} className="app_button">
      <i className="fa fa-power-off" aria-hidden="true" />
    </button>
  );
};

export default Button;
