import GenerateRandomNumber from "./GenerateRandomNumber";
import GenerateRandomLetter from "./GenerateRandomLetter";
import GenerateRandomSpecial from "./GenerateRandomSpecial";

export default function generatePassword(length, digits = false, specials = false, uppercase = false) {
    const password = [];
    for (let i = 0; i < length; i++) {
        password.push(GenerateRandomLetter());
        if (digits) password.push(GenerateRandomNumber());
        if (specials) password.push(GenerateRandomSpecial());
        if (uppercase) password.push(GenerateRandomLetter(true));
    }
    const shuffledPassword = [];
    for (let i = 0; i < length; i++) shuffledPassword.push(password[Math.floor(Math.random() * password.length)]);
    // return password.reduce((a, v) => a.splice(Math.floor(Math.random() * a.length), 0, v) && a, []).splice(0, length).join('');
    return shuffledPassword.join('');
}
