import React, {useState, useEffect} from "react";
import Button from "./Components/Button";
import Display from "./Components/Display";
import Range from "./Components/Range";
import Switcher from "./Components/Switcher";
import generatePassword from "./Functions/GeneratePassword";
import "./App.scss";

const App = () => {
    const [passwordText, changeText] = useState("");
    const [passwordLength, changeLength] = useState(10);
    const [parameters, changeParameters] = useState({
        digits: false,
        uppercase: false,
        specials: false,
    });

    const setParameters = param => {
        const changed = parameters[param] = !parameters[param];
        changeParameters({...parameters, ...changed})
    }

    const generate = () => {
        const {digits, specials, uppercase} = parameters;
        changeText(generatePassword(passwordLength, digits, specials, uppercase));
    };

    useEffect(() => {
        generate();
    }, []);

    return (
        <section className="app">
            <Display text={passwordText} />
            <Switcher onChange={() => setParameters('digits')} text="Use digits" />
            <Switcher onChange={() => setParameters('uppercase')} text="Use uppercase" />
            <Switcher onChange={() => setParameters('specials')} text="Use special symbols" />
            <div className="app_block">
                <div>Password length:</div>
                <div>{passwordLength}</div>
            </div>
            <Range range={passwordLength} onChange={(e) => changeLength(e.target.value)} />
            <Button onClick={() => generate} />
        </section>
    );
};

export default App;
