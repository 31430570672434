import React from "react";

const Switcher = (props) => {
    const {text} = props;
    const id = text.trim().replaceAll(" ", "-").toLocaleLowerCase();
    return (
        <div className="app_switcher">
            <input className="app_switcher-input" id={id} type="checkbox" onChange={props.onChange} />
            <label className="app_switcher-label" htmlFor={id}>{text}</label>
        </div>
    );
};

export default Switcher;
